@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.quantitySelector {
	background: $white;
	border: 1px solid $yellow;
	border-radius: 5px;
	display: flex;
	height: 48px;

	.moreButton,
	.lessButton {
		width: 33%;
		flex-basis: 33%;
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;

		& .icon {
			display: block;
			margin: 0 auto;
			height: 24px;
			color: $deep-green;

			&.faded {
				opacity: 0.5;
			}
		}

		&:disabled {
			cursor: default;
			opacity: 0.4;
		}
	}

	&.green {
		border-color: $deep-green;
	}
}

.inputSmall {
	flex-shrink: 1;
	text-align: center;
	display: block;
	width: 33%;
	font-size: 1.8rem;
	line-height: 2rem;
	flex-basis: 33%;
	color: $deep-green;
	flex-grow: 1;
}

.loading {
	flex-grow: 1;
	justify-content: center;
	text-align: center;
	display: block;
	align-self: center;
}
